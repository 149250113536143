<template>
	<div class="c-appdetails c-page" v-if="app && app.app_id">
		<header>
			<h1>Provided by {{data.operator}}</h1>
			<h2>{{data.title}}</h2>
		</header>
		<div class="c-appdetails__tags">
			<ul>
				<!-- <li :class="{'m--filtered': true || filters.get('stores') && filters.get('stores').includes(app.app_store.title)}">{{app.app_store.title}}</li> -->
				<li
					v-for="usecase in data.use_cases"
					:key="usecase"
					:class="{'m--filtered': true || filters.get('usecases') && filters.get('usecases').includes(usecase)}"
				>{{usecase}}</li>
			</ul>
		</div>
		<div class="c-appdetails__close" @click="closeClick">&#x2715;</div>
		<section class="c-appdetails__info">
			<div class="c-appdetails__icon">
				<img v-if="data.app_image && data.app_image.file" :src="`${assetsUrl}${data.app_image.file}`" />
			</div>
			<div class="c-appdetails__description">
				<span v-html="data.description"></span>
			</div>
		</section>
		<section v-if="(data.screenshots && data.screenshots.length > 0) || data.video">
			<h3>Take a look</h3>
			<div v-if="data.screenshots && (data.screenshots.length > 0 || data.screenshots.length > 1 && data.video)">
				<swiper
					:options="swiperOptions"
					:loop="true"
					:navigation="{prevEl: '.swiper-button-prev', nextEl: '.swiper-button-next'}"
					slidesPerView="auto"
					:centeredSlides="true"
					@transitionStart="swiperTransitionStart"
					@init="swiperInit"
				>
					<swiper-slide
						v-for="screenshot in data.screenshots"
						:key="screenshot.file"

					>
						<img :src="`${assetsUrl}${screenshot.file}`">
					</swiper-slide>
					<swiper-slide v-if="data.video">
						<video :src="`${assetsUrl}${data.video.file}`"></video>
					</swiper-slide>
					<div class="swiper-button-prev"><div class="icon"></div></div>
					<div class="swiper-button-next"><div class="icon"></div></div>
				</swiper>
			</div>
			<div v-else>
				<div v-if="data.screenshots && data.screenshots[0]">
					<img class="o-single-screenshot" :src="`${assetsUrl}${data.screenshots[0].file}`">
				</div>
				<div v-if="data.video">
					<video :src="`${assetsUrl}${data.video.file}`"></video>
				</div>
			</div>
		</section>
		<section class="c-appdetails__advantages" v-if="data.advantages">
			<span v-html="data.advantages"></span>
		</section>
		<section class="c-appdetails__relatedapps">
			<h3>Related Apps</h3>
			<ul>
				<template v-for="(app, index) in relatedApps">
					<li v-if="index < 4" :key="app.app_id" @click="relatedAppClick(app.app_id)">
						<img v-if="app.localized_data['en'].app_image" :src="`${assetsUrl}${app.localized_data['en'].app_image.file}`" />
						<p>{{ app.localized_data['en'].title }}</p>
					</li>
				</template>
			</ul>
		</section>
		<section class="c-appdetails__qrcode" v-if="qrcode">
			<div class="c-appdetails__qrcode-wrap">
				<div class="c-appdetails__qrcode-notice">
					Visit our app store
				</div>
				<img :src="qrcode">
			</div>
		</section>
	</div>
</template>

<script>
import store from "../store";
import { getAssetsUrl } from "../utils/Urls";

import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.scss';

import Plyr from 'plyr';

SwiperCore.use([Navigation]);

export default {
	name: 'AppDetails',
	computed: {
		app() {
			return store.getters.appById(this.$route.params.id)[0];
		},
		data() {
			return this.app && this.app.localized_data[this.$i18n.locale] || {};
		},
		assetsUrl() {
			return getAssetsUrl();
		},
		qrcode() {
			const storeqrs = new Map([
				['Industrial Edge', require('@/assets/qr-industrialedge.png')],
				['Mindsphere', require('@/assets/qr-mindsphere.png')],
			]);
			// if (this.data && this.data.qr_code && this.data.qr_code.file) {
			// 	return getAssetsUrl() + this.data.qr_code.file
			// }
			return this.app.app_store && storeqrs.get(this.app.app_store.title);
		},
		filters() { return store.state.filters }
	},
	watch: {
		app() {
			if (true || this.app) {
				this.setRelatedApps();
			}
			this.$nextTick(() => {
				if (!this.data.screenshots || this.data.screenshots.length == 0) {
					this.initVideos(document.querySelectorAll('.c-appdetails video'));
				}
			});
		}
	},
	data() {
		return {
			// FIXME: swiper options not being evaluated?
			swiperOptions: {
				slidesPerView: 2,
				navigation: {
					prevEl: '.swiper-button-prev',
					nextEl: '.swiper-button-next'
				}
			},
			relatedApps: [],
		}
	},
	methods: {
		closeClick() {
			this.$router.push({ name: 'Home' });
			return false;
		},
		async relatedAppClick(appId) {

			this.c.querySelectorAll('video').forEach((video) => {
				video.pause();
				video.currentTime = 0;
			});

			this.c.classList.add('related-transition');
			this.c.style.opacity = 0;
			await new Promise(r => setTimeout(r, 200));
			this.$router.push({ name: 'AppDetails', params: { id: appId } });
			await new Promise(r => setTimeout(r, 200));
			this.c.style.opacity = 1;
			this.c.classList.remove('related-transition');
		},
		setRelatedApps() {
			const usecases = store.getters.filters.get('usecases');
			// const usecases = ['Maintenance'];

			let apps = [];

			// fill with apps with same usecases
			// if (usecases) {
			// 	apps = store.getters.filteredApps.filter(
			// 		(app) => app.localized_data[this.$i18n.locale].use_cases.some(
			// 			(el) => usecases.includes(el)
			// 		)
			// 	);
			// }
			// apps.sort(() => .5 - Math.random());

			// fill with random filtered apps
			let i = 0;
			while (apps.length < 4 && store.getters.filteredApps.length -1 > apps.length && i < 50) {
				const randomApp = store.getters.filteredApps[Math.floor(Math.random() * store.getters.filteredApps.length)];
				if (randomApp != this.app && !apps.includes(randomApp)) {
					apps.push(randomApp);
				}
				i += 1;
			}
			// fill with random general apps
			i = 0;
			while (apps.length < 4 && store.state.apps.length -1 > apps.length && i < 50) {
				const randomApp = store.state.apps[Math.floor(Math.random() * store.state.apps.length)];
				if (randomApp != this.app && !apps.includes(randomApp)) {
					apps.push(randomApp);
				}
				i += 1;
			}
			this.relatedApps = apps;
		},
		swiperTransitionStart(swiper) {
			if (swiper && swiper.el) {
				swiper.el.querySelectorAll('video').forEach((video) => {
					video.pause();
					video.currentTime = 0;
				});
			}
		},
		swiperInit(swiper) {
			this.initVideos(swiper.el.querySelectorAll('video'));
		},
		initVideos(videos) {
			console.log(videos);
			videos.forEach((el) => {
				new Plyr(el, {
					iconUrl: require(`plyr/dist/plyr.svg`),
					clickToPlay: true,
					resetOnEnd: true,
					controls: ['play-large'],
				});
			});
		}
	},
	mounted() {
		this.c = document.querySelector('.c-appdetails');
		this.setRelatedApps();
		if (!this.data.screenshots || this.data.screenshots.length == 0) {
			this.initVideos(document.querySelectorAll('.c-appdetails video'));
		}
	},
	// setup() {
	// 	return {
	// 		modules: [Navigation],
	// 	};
	// },
	components: {
		Swiper,
		SwiperSlide
	}
}
</script>

<style lang="scss" scoped>
// @import "~bootstrap/scss/bootstrap-grid";

.c-appdetails {
	background-color: $color-background;
	font-size: vw(18);
	height: 100%;
	box-sizing: border-box;
	// overflow: auto;

	display: flex;
  flex-direction: column;

	&.related-transition {
		transition: opacity 0.2s linear;
	}

	section {
		margin-bottom: vw(40);
	}


	img {
		width: 100%;
		max-width: 100%;
	}

	header {
		position: relative;

		h2 {
			font-weight: 600;
		}
		margin-bottom: vw(40);
	}

	h3, & ::v-deep(h3) {
		font-size: vw(24);
		font-weight: 600;
		margin-bottom: vw(16);
	}

	&__close {
		height: vw(64);
		width: vw(64);
		border-radius: 50%;
		position: absolute;
		right: vw(53);
		top: vw($length-page-paddingtop);
		display: flex;
		align-items: center;
		justify-content: center;
		color: $color-background;
		cursor: pointer;

		background: $color-btn-close url("~@/assets/icons/x.svg") center center no-repeat;
		background-size: vw(26) auto;

		&:active, &:hover {
			background-color: $color-btn-close--active;
		}
	}

	&__tags {
		ul {
			margin: 0 0 vw(54);
			padding: 0;
			list-style: none;
			li {
				display: inline-block;
				border:1px solid $color-page-tags;
				border-radius: vw(20);
				color: $color-page-tags;
				font-size: vw(14);
				line-height: vw(36);
				padding: 0 vw(26);
				margin: 0 vw(10) vw(10) 0;
				opacity: 0.8;

				&.m--filtered {
					padding-left: vw(36);
					background-image: url("~@/assets/icons/check.svg");
					background-repeat: no-repeat;
					background-position: left vw(16) center;
					background-size: vw(11) auto;
				}
			}
		}
	}

	&__info {
		display: flex;
		flex-direction: row;
	}

	&__icon {
		width: vw(230);
		height: vw(130);
		background: #ebf0f5;
		margin-right: vw(18);
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: vw(8);

		img {
			width: vw(110);
			height: auto;
		}
	}
	&__description {
		flex: 1;
		font-size: vw(22);
		max-width: 50vw;
	}

	.swiper-container {

		width: vw(870);
		height: vw(385);
		margin-left: 0;

		::v-deep() .swiper-slide {

			width: 75%;
			margin: auto;
			transition: opacity 0.2s linear;
			opacity: 0.3;

			&-active {
				opacity: 1;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
				background: transparent;
			}
			video {
				width: 100%;
				height: 100%;
			}
		}

		.swiper-button-prev, .swiper-button-next {
			position: absolute;
			z-index: 10;
			top: 0;
			bottom: 0;
			width: vw(36*2+32);
			margin: auto;

			cursor: pointer;

			.icon {
				position: absolute;
				top: 0;
				bottom: 0;
				margin: auto;
				height: vw(32);
				aspect-ratio: 1;
				border-radius: 100%;
				background: $color-swiper-navigation none center center no-repeat;
				background-size: vw(24) auto;
			}

			&:active {
				.icon {
					background-color: $color-btn-close--active;
				}
			}

			&.swiper-button-prev {
				left: 0;
				.icon {
					left: vw(36);
					background-image: url("~@/assets/icons/chevron-left.svg");
				}
			}
			&.swiper-button-next {
				right: 0;
				.icon {
					right: vw(36);
					background-image: url("~@/assets/icons/chevron-right.svg");
				}
			}
		}
	}

	.o-single-screenshot {
		height: vw(385);
		width: auto;
	}

	&__advantages {
		::v-deep(*) {
			font-size: vw(18);
		}
		::v-deep(b) {
			display: block;
			@extend h3;
		}
		::v-deep(ul) {
			padding: 0 0 0 vw(21);
			list-style-type: none;
			li {
				position: relative;
				&:before {
					content: '\2013';
					position: absolute;
					left: vw(-20);
				}
			}
		}
	}

	&__relatedapps {
		ul {
			padding: 0;
			margin: 0;
			display: flex;
			flex-wrap: wrap;
			margin-left: vw(-8);
			width: calc(100% + vw(8));

			li {
				width: vw(220);
				aspect-ratio: 1;
				margin: vw(8);
				padding: vw(25);
				overflow: hidden;
				background: #333353;
				border-radius: 10%;

				display: flex;
				flex-direction: column;
				align-items: center;
				text-align: center;

				font-size: vw(14);
				line-height: 2em;
				font-weight: 900;

				transition: background-color 0.03s linear;

				&:active {
					background: #555575;
				}

				img {
					width: vw(80);
					aspect-ratio: 1;
					object-fit: contain;
					margin-bottom: vw(15);
				}
				p {
					flex: 1;
					display: flex;
					align-items: center;
					margin-bottom: 0;
				}
			}
		}
	}

	&__qrcode {
		flex: 1;
		display: flex;
    flex-direction: row;
    align-items: flex-end;
		margin-bottom: vw(57) !important;

		&-wrap {
			display: flex;
			> img, div {
				width: vw(108);
				aspect-ratio: 1;
			}
		}
		&-notice {
			background-color: $color-visit;
			margin-right: vw(16);
			padding: vw(12) vw(10);
			display: flex;
			align-items: flex-end;
			font-weight: 900;
		}
	}
}
</style>